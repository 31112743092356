body {
  font-family: 'Roboto', Arial, sans-serif;
  background-color: #1e1e1e;
  color: #ffffff;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* General header styles */
.header-container {
  background-color: #282828;
  color: #ffffff;
  padding-bottom: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.logo-container img {
  max-height: 80px;
}

.welcome-text h1 {
  flex-grow: 1;
  text-align: center;
  color: #ff6600;
  font-size: 50px;
}

/*language selector*/
.language-select
{
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  align-items: center;
}
/* the flag icon */
.flag-icon
{
  width: 28px;
  margin-left: 10px;
  height: 16px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}
/* changes the color of the flag icon */
.flag-icon:hover
{
  transform: scale(1.1);
}

.nav-links-container {
  margin-top: 10px;
  padding: 10px 0;
  background-color: #383838;
}

.nav-links-container .nav-link {
  color: #ffffff;
  margin: 0 10px;
  transition: color 0.3s;
}

.nav-links-container .nav-link:hover {
  color: #ff6600;
}

.icons-container {
  margin-top: 10px;
}

.icons-container button {
  background: none;
  border: none;
  color: #ffffff;
  padding: 5px;
  margin-left: 10px;
  cursor: pointer;
  transition: transform 0.2s;
}

.icons-container button:hover {
  transform: scale(1.1);
}

header h1 {
  color: #ff6600;
  font-size: 24px;
  margin: 0;
}



nav {
  display: flex;
  justify-content: center;
  gap: 20px;
}

nav a {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 15px;
  transition: color 0.3s;
}

nav a:hover {
  color: #ff6600;
}

button {
  background-color: #ff6600;
  color: white;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  transition: transform 0.2s, background-color 0.3s;
}

.products-container {
  padding: 20px;
}

.products-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  width: 280px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-card h3 {
  color: #003366;
}

.btn-primary {
  display: flex;
  align-items: center;
  gap: 5px;
}




button:hover {
  transform: scale(1.05);
  background-color: #e55a00;
}

.products-container {
  padding: 20px;
}

.products-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  width: 280px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-card h3 {
  color: #003366;
}

.btn-primary {
  display: flex;
  align-items: center;
  gap: 5px;
}


.products {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.product-card {
  background-color: #2a2a2a;
  border-radius: 8px;
  overflow: hidden;
  width: 280px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-card img {
  width: 100%;
  height: auto;
}

.product-card h3 {
  font-size: 20px;
  color: #ff6600;
  margin: 16px;
}

.product-card p {
  color: #cccccc;
  margin: 0 16px 16px;
}

.product-card .price {
  color: #ffffff;
  font-weight: bold;
  margin: 0 16px 16px;
}





.container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #003366;
  border-bottom: 2px solid #cccccc;
  padding-bottom: 10px;
}

form {
  margin-top: 20px;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.btn-primary {
  background-color: #003366;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.btn-primary:hover {
  background-color: #002244;
}

.btn-secondary {
  background-color: #cccccc;
  color: #000000;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.btn-secondary:hover {
  background-color: #aaaaaa;
}

.products ul {
  list-style-type: none;
  padding: 0;
}

.products li {
  background-color: #ffffff;
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.products h3 {
  margin: 0;
  color: #003366;
}

.products p {
  margin: 5px 0;
}

.products button {
  background-color: #003366;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
}

.products button:hover {
  background-color: #002244;
}

@media (max-width: 768px) {
  nav {
    flex-direction: column;
  }

  .products {
    flex-direction: column;
    align-items: center;
  }
}


footer {
  background-color: #282828;
  padding: 20px;
  text-align: center;
  color: #888888;
  font-size: 14px;
}

footer a {
  color: #ff6600;
  text-decoration: none;
}

footer a:hover {
  color: #ffffff;
}

.list-group-item {
  overflow: hidden;
  word-wrap: break-word;
}

.list-group-item div:first-child {
  min-width: 0;
}

.img-thumbnail {
  max-width: 80px;
  max-height: 80px;
  object-fit: cover;
  margin-right: 5px;
}

@media (max-width: 768px) {
  .list-group-item {
    flex-direction: column;
  }

  .list-group-item div:last-child {
    margin-top: 1rem;
    align-self: flex-start;
  }
}

.overflow-auto {
  display: flex;
  overflow-x: auto;
  scrollbar-width: thin;
  gap: 10px;
}

.overflow-auto::-webkit-scrollbar {
  height: 8px;
}

.overflow-auto::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.overflow-auto::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.img-thumbnail {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

footer {
  background-color: #282828;
  color: #ffffff;
  padding: 20px 0;
}

footer h5 {
  color: #ff6600;
  margin-bottom: 15px;
}

footer a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

footer a:hover {
  color: #ff6600;
}

footer img {
  max-height: 30px;
}

footer hr {
  border: none;
  height: 1px;
  background-color: #ffffff;
  opacity: 0.2;
  margin: 20px 0;
}
